<template>
  <div class="col working_together_wrapper">
    <card>
      <!-- Start Why this is important  -->
      <div class="importance_wrapper">
        <!-- <h4 class="title" :class="{ 'text-right': isRtl() }">
          {{ localization("How will this work") }}
        </h4> -->
        <h4
          :class="{ 'text-right': isRtl() }"
          v-if="team_pages_titles_content[3]"
          v-html="team_pages_titles_content[3].body"
        ></h4>
        <div
          :class="{ 'text-right': isRtl() }"
          v-if="team_pages_content[3]"
          v-html="team_pages_content[3].body"
        ></div>
      </div>
      <!-- End Why this is important  -->
    </card>
    <!-- Start Team Select Box  -->
    <card>
      <!-- Start Header -->
      <div
        class="header_contact_wrapping mb-4"
        :class="{ 'd-flex flex-row-reverse': isRtl() }"
      >
        <h4 class="card-title" :class="{ 'd-flex flex-row-reverse': isRtl() }">
          {{ localization("Choose Team") }}
        </h4>
      </div>
      <!-- End Header -->
      <div class="row">
        <div class="col-md-4">
          <el-select
            v-model="team_id"
            :placeholder="`${localization('Please select team')}`"
            style="width: 100%"
            @change="handlerTeamTasks"
          >
            <el-option
              v-for="team in allTeamsList"
              :key="team.id"
              :label="team.name"
              :value="team.id"
            ></el-option>
          </el-select>
        </div>
      </div>
    </card>
    <!-- End Team Select Box  -->
    <card>
      <!-- Tasks List  -->
      <!-- //// start Large Screens  -->
      <server-table
        :DataItems="allTeamTasks"
        @Refresh="Refresh"
        @reset="filter = {}"
        :title="'Tasks'"
        hidden_export_import
        admin_permission="admin_tasks"
        edit_permission="add_tasks"
        :loading="loadingValue"
      >
        <!-- search & filter fields  -->
        <!-- search by topic  -->
        <!-- <template #filter>
          <el-select
            v-model="filter.topic_id"
            clearable
            :placeholder="`${localization('Topic')}`"
            class="mt-2 select-default w-100"
          >
            <el-option
              v-for="(option, index) in $store.getters['topics/topics']"
              :key="index"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </template> -->

        <!-- Tasks Table  -->
        <template #columns>
          <!-- Task Name  -->
          <el-table-column
            prop="name"
            sortable
            :label="`${localization('Name')}`"
          ></el-table-column>
          <!-- Task Topic -->
          <!-- <el-table-column
            prop="topic_name"
            sortable
            :label="`${localization('Topic')}`"
            class="d-none d-md-block"
          ></el-table-column> -->

          <!-- //? Task Actions  -->
          <el-table-column prop="" label="_">
            <template slot-scope="scope">
              <div class="td_actions flex justify-content-end">
                <!-- Edit Task Button  -->
                <!-- <a
                  @click="openShareDialog"
                  v-tooltip.top-center="`Share personal strategy`"
                  class="btn btn-warning btn-simple btn-link"
                >
                  <i class="fa fa-share-alt"></i
                ></a> -->
                <!-- show task button  -->
                <router-link
                  :to="`/tackling-project/${scope.row.id}`"
                  v-tooltip.top-center="
                    `${localization('Show personal strategy')}`
                  "
                  class="btn btn-info btn-link btn-xs"
                >
                  <i class="fa fa-eye"></i
                ></router-link>
                <!-- Delete Task Button  -->
                <a
                  @click="remove(scope.row.id)"
                  v-tooltip.top-center="
                    `${localization('Delete personal strategy')}`
                  "
                  class="btn btn-danger btn-simple btn-link"
                >
                  <i class="fa fa-times"></i
                ></a>
              </div>
            </template>
          </el-table-column>
        </template>

        <!-- Add Task Button @click="open_add_task_dialog(1, {})"  -->
        <template #action>
          <!-- <div wide @click="add_team_task = true" class="" :disabled="team_id == null">
            <el-icon class="el-icon-plus"></el-icon>
            Add Task
          </div> -->
          <button
            @click="add_team_task = true"
            :disabled="team_id == null"
            class="cu_btn_add"
          >
            <el-icon class="el-icon-plus"></el-icon>
            {{ localization("Add Task") }}
          </button>
        </template>
      </server-table>
      <!-- //// end Large Screens  -->

      <el-dialog
        title="Share personal strategy"
        :visible.sync="share_personal_dialog"
        width="50%"
        top="4vh"
      >
        <el-select
          v-model="team_id"
          placeholder="Please select team"
          style="width: 100%"
        >
          <el-option
            v-for="team in allTeamsList"
            :key="team.id"
            :label="team.name"
            :value="team.id"
          ></el-option>
        </el-select>
        <div slot="footer" class="dialog-footer dialog_footer">
          <el-button @click="openShareDialog" class="text-center d-block">{{
            localization("Cancel")
          }}</el-button>
          <el-button
            type="submit"
            @click="openShareDialog"
            class="text-center d-block text-capitalize"
            >Share</el-button
          >
        </div>
      </el-dialog>

      <!-- Start Add Task  -->
      <el-dialog
        :title="`${localization('Add Task')}`"
        :visible.sync="add_team_task"
        width="50%"
        top="4vh"
      >
        <el-form
          :model="teamTaskData"
          :rules="rules"
          ref="taskRef"
          @submit.prevent="handlerAddTeamTasks"
        >
          <el-form-item :label="`${localization('Name')}`" prop="name">
            <el-input
              :placeholder="`${localization('Name')}`"
              v-model="teamTaskData.name"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="`${localization('Description')}`"
            prop="description"
          >
            <el-input
              :placeholder="`${localization('Description')}`"
              v-model="teamTaskData.description"
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="
            dialog-footer
            dialog_footer
          "
        >
          <el-button
            @click="add_team_task = false"
            class="text-center d-block"
            >{{ localization("Cancel") }}</el-button
          >

          <el-button
            @click="handlerAddTeamTasks"
            class="
              text-center
              d-block
              text-capitalize
            "
            >{{ localization("Save") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- End Add Task  -->
      <div
        class="team_routes_wrapper"
        :class="{ 'd-flex flex-row-reverse': isRtl() }"
      >
        <router-link class="back_link" to="/teams"
          ><i class="fa fa-arrow-left back_icon mr-1"></i>
          {{ localization("Back to team") }}
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
import { Card, Table as LTable } from "src/components/index";
import { mapGetters } from "vuex";
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";

export default {
  components: {
    Card,
    LTable,
    ServerTable
  },
  data() {
    return {
      filter: {},
      topic_id: null,
      share_personal_dialog: false,
      team_id: null,
      add_team_task: false,
      teamTaskData: {
        name: null,
        description: null
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.$store.dispatch("user/get_all_teams_content");
    this.Refresh();
    this.$store.dispatch("user/get_all_teams");
    this.$store.dispatch("topics/GetTopics", { query: null });
    this.handlerTeamTasks();
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      team_pages_content: ["user/get_team_pages_content"],
      team_pages_titles_content: ["user/get_team_pages_titles_content"],
      allTeamsList: ["user/get_teams"],
      allTeamTasks: ["user/getAllTeamTasks"]
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    tasks() {
      return this.$store.getters["user/tasks"];
    }
  },
  //   add_team_task
  // team_tasks
  // show_team_task

  methods: {
    Refresh(query) {
      this.$store
        .dispatch("user/userTask", { id: this.id, query: query })
        .then(() => {
          this.$store.dispatch("topics/GetTopics", { query: null });
        });
    },
    remove(id) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/deleteTeamTaskTalkingProject", id)
            .then(_ => {
              this.handlerTeamTasks();
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    openShareDialog() {
      this.share_personal_dialog = !this.share_personal_dialog;
    },
    // handlerTeamTasks
    handlerTeamTasks(query) {
      this.$store.dispatch("user/handlerTeamTasks", {
        id: this.team_id,
        query: query
      });
      // this.$store.getters["user/getTeamExist"] = this.team_id;
    },
    // addNewTaskTalkingProject
    // handlerAddTeamTasks() {
    //   this.$store.dispatch("user/addNewTaskTalkingProject", {id:this.team_id});
    // },

    handlerAddTeamTasks() {
      // this.$store
      //   .dispatch("user/addNewTaskTalkingProject", {
      //     id: this.team_id,
      //     query: this.teamTaskData
      //   })
      //   .then(_ => {
      //     this.handlerTeamTasks();
      //     this.add_team_task = false;
      //     Swal.fire({
      //       title: "",
      //       icon: "success",
      //       text: `${this.localization("Done")}`,
      //       confirmButtonText: `${this.localization("Ok")}`,
      //       type: "success",
      //       confirmButtonClass: "btn btn-success ",
      //       buttonsStyling: false
      //     });
      //   });
      this.$refs["taskRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/addNewTaskTalkingProject", {
              id: this.team_id,
              query: this.teamTaskData
            })
            .then(res => {
              // console.log(res.task.id);
              this.handlerTeamTasks();
              this.add_team_task = false;
              this.teamTaskData.name = null;
              this.teamTaskData.description = null;

              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
              this.$router.push(`/tackling-project/${res.task.id}`);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cu_btn_add {
  border: none;
  background: none;
  display: block;
  &:hover {
    background-color: #ecf5ff;
    color: #66b1ff;
  }
}
.working_together_wrapper {
  .team_routes_wrapper {
    display: flex;
    gap: 10px;
    .back_link {
      color: #777;
      background-color: #eee;
      padding: 8px 10px;
      display: block;
      width: fit-content;
      margin: 10px 0;
      border-radius: 8px;
      transition: 0.3s;
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
  }
  // Start Header And All Sections Style
  .card-title {
    color: #777;
    font-size: 17px !important;
  }
  .importance_wrapper {
    color: #777;
    margin-bottom: 3rem;
    h4 {
      margin-top: 10px;
    }
    ol {
      li {
        margin-bottom: 10px;
        line-height: 1.7;
      }
    }
  }
  .dialog_footer {
    display: flex;
    justify-content: end;
    align-items: center;
    @media (min-width: 320px) and (max-width: 991px) {
      justify-content: center;
    }
  }
}
// // End Collapse
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
</style>
